import * as React from "react"
import Navbar from "../../components/Navbar";
import Helmet from "react-helmet";
import { StaticImage } from "gatsby-plugin-image"
import TitleSection from "../../components/TitleSection";
import "./website-koppeling-aanmelding.scss";      // Deze moet weg of eigen scss krijgen.
import Footer from "../../components/Footer";

// markup
const WebsiteKoppelingAanmelding = () => {
    return (
        <>
            <Helmet>
                <title>Careweb Signalering | Dossier compleet</title>
                <meta name="description" content="Ontvang automatisch meldingen wanneer een dossier niet compleet is of aandacht vereist."/>
                <meta property="og:title" content="Careweb Signalering | Dossier compleet"/>
                <meta property="og:url" content="https://meneerjansen-ict.nl/careweb/dossier-compleet"/>
                <meta property="og:description" content="Ontvang automatisch meldingen wanneer een EPD niet volledig is of aandacht vereist."/>
                <meta property="og:image" content="https://meneerjansen-ict.nl/images/logo.png"/>
                <body className="page-website-integration" />
            </Helmet>

            <Navbar/>

            <main>

                <article className="bg-circle">
                    <TitleSection title="Dossier compleet" subtitle="Automatische signalering of een dossier volledig is"/>

                    <div className="content">
                        <div className="cols">

                            <div className="col-xs-100 col-md-50 col-lg-33">
                                <StaticImage src="../../images/dossier-compleet-dossier.png" alt="Afbeelding van een verkeerslicht" quality={100}/>
                            </div>

                            <div className="col-xs-100 col-md-50 col-lg-66 ">
                                <section className="text-based">
                                    <p>
                                        In één oogopslag zien of het dossier in Careweb volledig is.
                                    </p>
                                    {/*<section className="text-based">*/}
                                    {/*    <p>*/}
                                    {/*        Bekijk hier een <Link to="/demo/website-koppeling-demo">voorbeeld aanmeldformulier</Link>.*/}
                                    {/*    </p>*/}
                                    {/*</section>*/}

                                    <h3 style={{ marginTop: '2em'}}>Controle op o.a.:</h3>
                                    <ul className="check">
                                        <li>Identiteit</li>
                                        <li>Verwijsbrief aanwezig</li>
                                        <li>Intakeverslag ingevuld</li>
                                        <li>Behandelplan</li>
                                        <li>Behandelovereenkomst</li>
                                        <li>Verlopen van zorgtoewijzingen (Jeugd en WMO)</li>
                                    </ul>

                                    <h3>Allerlei maatwerk controles mogelijk</h3>
                                    <ul className="check">
                                        <li>Directe tijd regiebehandelaar</li>
                                        <li>Voldoende afspraken ingepland</li>
                                        <li>Allerlei eigen controles mogelijk</li>
                                    </ul>
                                </section>

                            </div>

                        </div>
                    </div>
                </article>


                <article>
                    <div className="content">

                        <section className="text-based" style={{ marginBottom: '0'}}>
                            <div className="cols">
                                <div className="col-xs-100">
                                    <h2 className="sm-fsize-70">Keuze implementatie en abonnement</h2>

                                    <p>
                                        Een Dossier-compleet abonnement kost maandelijks 119,- excl. btw.
                                    </p>

                                    <p>
                                        De implementatie is afhankelijk van de wensen en is mogelijk vanaf 999,- excl. btw.
                                    </p>
                                </div>
                            </div>
                        </section>

                        <section className="ButtonContainer align-center">
                            <a href="mailto:info@meneerjansen-ict.nl?subject=Infoblad Dossier Compleet&body=Graag ontvang ik het infoblad voor het Careweb abonnement 'Dossier Compleet'." className="ButtonLink">Vraag infoblad aan</a>
                        </section>

                    </div>
                </article>

                {/*<article>*/}
                {/*    <div className="content">*/}

                {/*        <section className="text-based">*/}
                {/*            <div className="cols">*/}
                {/*                <div className="col-xs-100 faq">*/}
                {/*                    <h2>Veel gestelde vragen</h2>*/}

                {/*                    <h3 id="email_not_safe">Wij doen nu aanmelding via e-mail. Dat is toch ook veilig?</h3>*/}
                {/*                    <p>*/}
                {/*                        Gegevens die verstuurd worden via de website koppeling gaan over een beveiligde, versleutelde verbinding direct het EPD in. Hiermee zijn de gegevens veilig en AVG-proof opgeslagen.<br/>*/}
                {/*                        Dit is niet altijd het geval wanneer je persoonsgegevens vanaf je website via e-mail zou versturen. Zonder een aantal beveiligingseisen wordt dit door de AVG gezien als 'gebrek aan beveiliging'*/}
                {/*                        (zie  <a target="_blank" rel="noreferrer" href="https://www.autoriteitpersoonsgegevens.nl/nl/onderwerpen/beveiliging/beveiliging-van-persoonsgegevens#hoe-kan-ik-veilig-persoonsgegevens-via-e-mail-versturen-6172">Autoriteit Persoonsgegevens</a>).*/}
                {/*                    </p>*/}

                {/*                    <h3>Waarom zijn er maandelijkse kosten verbonden aan een website koppeling?</h3>*/}
                {/*                    <p>*/}
                {/*                        Dat heeft een aantal redenen.*/}
                {/*                    </p>*/}

                {/*                    <ul>*/}
                {/*                        <li>Met het maandelijkse bedrag betaal je voor het gebruik en onderhoud van de koppelsoftware en server</li>*/}
                {/*                        <li>De software wordt continue up-to-date gehouden</li>*/}
                {/*                        <li>De server wordt doorlopend gemonitord voor o.a. beveiliging en werking</li>*/}
                {/*                        <li>Voor de hosting van de koppeling worden maandelijks kosten gemaakt die worden doorberekend</li>*/}
                {/*                    </ul>*/}

                {/*                    <h3>Kan ik ook meer dan één formulier koppelen?</h3>*/}
                {/*                    <p>*/}
                {/*                        Ja dat kan, je kunt zoveel formulieren koppelen als gewenst. Op ieder volgend formulier geldt een korting van 50%.*/}
                {/*                    </p>*/}


                {/*                    <h3>Moet er voor de koppeling software worden geïnstalleerd op onze server(s)?</h3>*/}
                {/*                    <p>*/}
                {/*                        Nee, er hoeft niets geïnstalleerd te worden. <br/>Er moet een link en een stukje HTML opgenomen worden op je eigen website.*/}
                {/*                        Hiervoor ontvang je een handleiding. Enige kennis van HTML is wel vereist. Mocht je dit niet hebben, dan kunnen wij de inbouw verzorgen.*/}
                {/*                    </p>*/}

                {/*                </div>*/}
                {/*            </div>*/}

                {/*        </section>*/}

                {/*    </div>*/}
                {/*</article>*/}

            </main>

            <Footer />
        </>

    )
}

export default WebsiteKoppelingAanmelding;
